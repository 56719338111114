import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import {
  Button,
  Col,
  DatePicker,
  Drawer,
  Form,
  FormProps,
  Input,
  InputNumber,
  message,
  Modal,
  Popover,
  Row,
  Select,
  Space,
} from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'

import { Loading } from '@/components/Loading'
import { useFormAllValues } from '@/hooks/hooks'
import { formAutoTrimOnBlur, selectFilterOption } from '@/utils/utils'
import { useCURRENCY_OPTS } from '../../consts/consts'
import { useCOUNTRY_OPTS } from '../../consts/country'
import { apiAddFreightTemplate, apiEditFreightTemplate, apiGetFreightTemplateDetail } from '../apis'
import { IFreightTemplateDetail, TFreightTemplateFormValues } from '../interface'
import { popupSlot } from '../utils'
import { templateDetailsRender } from './DetailDrawer.templateDetailsRender'
import { createInitialFormValues, textInputFields, useChannelOpts, useCountryPartitionOpts } from './utils'

interface IDetailDrawerProps<T extends 'add' | 'copy' | 'edit' | 'view'> {
  actionType: T
  id: T extends 'add' ? null : number
  onSuccess?: () => void
}

const updatePopup = popupSlot.insert(null)

export const DetailDrawer = Object.assign(
  <T extends 'add' | 'copy' | 'edit' | 'view'>({
    actionType,
    id,
    onSuccess,
    destroy,
  }: IDetailDrawerProps<T> & { destroy: () => void }) => {
    const { t } = useTranslation()
    const { open, onHide, afterOpenChange } = popupSlot.useAntdPopupAnimation(destroy)

    const [fetching, setFetching] = useState(actionType !== 'add' && id != null)

    const [form] = Form.useForm<TFreightTemplateFormValues>()
    const initialFormValues = useMemo(() => createInitialFormValues(actionType, null), [actionType])
    const allValues = useFormAllValues(form, initialFormValues)

    const { channelOpts } = useChannelOpts()

    const countryOpts = useCOUNTRY_OPTS()

    const { countryPartitionOptsLoading, countryPartitionOpts, countryPartitionRequired } = useCountryPartitionOpts(
      allValues.countryCode,
      actionType === 'view' || actionType === 'edit',
    )
    useEffect(() => void form.validateFields(['countryPartition']), [countryPartitionRequired, form])

    const currencyOpts = useCURRENCY_OPTS('EUR,GBP,CAD,USD,AUD,RMB')

    const formProps: FormProps<TFreightTemplateFormValues> = {
      form,
      scrollToFirstError: { block: 'center', behavior: 'smooth' },
      layout: 'vertical',
      disabled: actionType === 'view',
      preserve: true,
      initialValues: initialFormValues,
      onBlur: e => formAutoTrimOnBlur({ event: e, form, includeField: textInputFields }),
    }

    const emptyAllWeight = () => {
      allValues.templateDetails.forEach((_d, i) => {
        const path1: Parameters<typeof form.setFieldValue>[0] = ['templateDetails', i, 'startingWeight']
        const path2: Parameters<typeof form.setFieldValue>[0] = ['templateDetails', i, 'endingWeight']
        form.setFieldValue(path1, i === 0 ? 0 : undefined)
        form.setFieldValue(path2, undefined)
        form.validateFields([path1, path2])
      })
    }

    useEffect(() => {
      if (actionType === 'add' || id == null) return
      setFetching(true)
      apiGetFreightTemplateDetail(id)
        .then(res => {
          const values = createInitialFormValues(actionType, res)
          form.setFieldsValue(values)
        })
        .finally(() => setFetching(false))
    }, [actionType, form, id])

    const titleArr = [
      {
        ['add']: t('2-common.xin-zeng'),
        ['copy']: `${t('2-common.xin-zeng')}`,
        ['edit']: t('2-common.bian-ji'),
        ['view']: t('2-common.cha-kan'),
      }[actionType],
    ]
    titleArr.push(t('Logistics.mo-ban'))
    if (actionType === 'copy') titleArr.push(`(${t('2-common.fu-zhi')})`)

    return (
      <Drawer
        title={titleArr.join(' ')}
        width={1100}
        extra={
          <Space>
            {(actionType === 'add' || actionType === 'copy' || actionType === 'edit') && (
              <Button
                type="primary"
                onClick={async () => {
                  form.submit() // 触发 scrollToFirstError
                  let values = await form.validateFields()
                  values = form.getFieldsValue(true)
                  if (values.templateDetails.length === 0)
                    return void message.error(t('Logistics.qing-tian-jia-zhong-liang-fan-wei'))
                  Modal.confirm({
                    title:
                      actionType === 'edit'
                        ? t('Logistics.que-ren-yao-bian-ji-ma')
                        : t('Logistics.que-ren-yao-xin-zeng-ma'),
                    onOk: async () => {
                      if (actionType === 'add' || actionType === 'copy') {
                        await apiAddFreightTemplate(values as IFreightTemplateDetail<false>)
                      } else if (actionType === 'edit') {
                        await apiEditFreightTemplate(values as IFreightTemplateDetail<true>)
                      }
                      message.success(t('2-common.cao-zuo-cheng-gong'))
                      onHide()
                      onSuccess?.()
                    },
                  })
                }}
              >
                {actionType === 'edit' ? t('2-common.bian-ji') : t('2-common.xin-zeng')}
              </Button>
            )}
            <Button onClick={onHide}>{t('2-common.guan-bi')}</Button>
          </Space>
        }
        open={open}
        onClose={onHide}
        afterOpenChange={afterOpenChange}
      >
        {fetching ? (
          <Loading />
        ) : (
          <Form {...formProps}>
            <Row gutter={12}>
              <Col span={6}>
                <Form.Item label={t('Logistics.mo-ban-ming-cheng')} name="templateName" rules={[{ required: true }]}>
                  <Input
                    disabled={formProps.disabled || actionType === 'edit'}
                    placeholder={t('2-common.qing-shu-ru')}
                    allowClear
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label={t('Logistics.wu-liu-qu-dao')} name="channelCode" rules={[{ required: true }]}>
                  <Select<string, NonNullable<typeof channelOpts>[number]>
                    placeholder={t('2-common.qing-xuan-ze')}
                    disabled={formProps.disabled || actionType === 'edit'}
                    showSearch
                    filterOption={selectFilterOption}
                    options={channelOpts}
                    onChange={(_v, option) => {
                      if (!option || Array.isArray(option)) {
                        form.setFieldValue('channelEName', undefined)
                        form.setFieldValue('channelCName', undefined)
                      } else {
                        form.setFieldValue('channelEName', option.data.channelEName)
                        form.setFieldValue('channelCName', option.data.channelCName)
                      }
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label={t('Logistics.guo-jia')} name="countryCode" rules={[{ required: true }]}>
                  <Select
                    placeholder={t('2-common.qing-xuan-ze')}
                    disabled={formProps.disabled || actionType === 'edit'}
                    showSearch
                    filterOption={selectFilterOption}
                    options={countryOpts}
                    onChange={() => {
                      form.setFieldValue('countryPartition', undefined)
                      emptyAllWeight()
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  label={t('Logistics.guo-jia-fen-qu')}
                  name="countryPartition"
                  hasFeedback={countryPartitionOptsLoading ? true : undefined}
                  validateStatus={countryPartitionOptsLoading ? 'validating' : undefined}
                  rules={[
                    { required: countryPartitionRequired, message: countryPartitionOptsLoading ? '' : undefined },
                  ]}
                >
                  <Select
                    placeholder={t('2-common.qing-xuan-ze')}
                    disabled={formProps.disabled || actionType === 'edit'}
                    showSearch
                    filterOption={selectFilterOption}
                    options={countryPartitionOpts}
                    onChange={() => emptyAllWeight()}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  required
                  label={
                    <Space size={6}>
                      <span>{t('Logistics.mo-ban-you-xiao-qi-bei-mei-shi-jian')}</span>
                      <Popover
                        overlayStyle={{ maxWidth: 360 }}
                        content={t(
                          'Logistics.tian-xie-shi-jian-wei-bei-mei-shi-jian-shi-jian-xiang-cha-12-ge-xiao-shi',
                        )}
                      >
                        <InfoCircleOutlined />
                      </Popover>
                    </Space>
                  }
                >
                  <Form.Item
                    noStyle
                    label={t('Logistics.mo-ban-you-xiao-qi')}
                    name="effectiveDate"
                    rules={[{ required: true }]}
                  >
                    <div>
                      <DatePicker.RangePicker
                        style={{ width: '100%' }}
                        allowClear={false}
                        showTime={{ format: 'HH:mm:ss' }}
                        format={$F_YMDHms}
                        value={
                          allValues.effectiveDate && allValues.expirationDate
                            ? [dayjs(allValues.effectiveDate), dayjs(allValues.expirationDate)]
                            : undefined
                        }
                        onChange={(_dates, [s, e]) => {
                          const start = s || undefined
                          const end = e || undefined
                          form.setFieldValue('effectiveDate', start)
                          form.setFieldValue('expirationDate', end)
                          form.validateFields(['effectiveDate'])
                        }}
                      />
                    </div>
                  </Form.Item>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label={t('Logistics.ji-pao-xi-shu')} name="bubbleCoefficient" rules={[{ required: true }]}>
                  <InputNumber
                    style={{ width: '100%' }}
                    placeholder={t('2-common.qing-shu-ru')}
                    min={1}
                    precision={0}
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label={t('Logistics.jin-wei-fang-shi')} name="carryMethod" rules={[{ required: true }]}>
                  <Select
                    disabled
                    placeholder={t('2-common.qing-xuan-ze')}
                    options={[
                      { value: 1, label: t('Logistics.bu-jin-wei') },
                      { value: 2, label: t('Logistics.xiang-shang-qu-zheng') },
                    ]}
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  label={t('Logistics.shi-ji-fei-yong-bi-zhong')}
                  name="actualFeeCurrencyType"
                  rules={[{ required: true }]}
                >
                  <Select placeholder={t('2-common.qing-xuan-ze')} options={currencyOpts} />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label={t('Logistics.zui-di-ji-fei-zhong-liang-g')} name="minimumChargeableWeight">
                  <InputNumber
                    style={{ width: '100%' }}
                    placeholder={t('2-common.qing-shu-ru')}
                    min={0}
                    precision={2}
                    step={10}
                  />
                </Form.Item>
              </Col>
            </Row>
            {templateDetailsRender(
              form,
              allValues,
              actionType === 'view' ||
                !allValues.countryCode ||
                countryPartitionOptsLoading ||
                (!!countryPartitionOpts?.length && !allValues.countryPartition),
            )}
          </Form>
        )}
      </Drawer>
    )
  },
  {
    open: <T extends 'add' | 'copy' | 'edit' | 'view'>(props: IDetailDrawerProps<T>) => {
      updatePopup(<DetailDrawer {...props} destroy={() => updatePopup(null)} />)
    },
  },
)
